import { reactive, ref } from "vue";
import { getPostArticleList } from "../../api/article";
import { defaultThumbnail } from "../../utils/thumbnail";
export default {
  name: "ArchiveDetails",
  setup(props) {
    let pageSize = 10;
    let postArticles = reactive([]);
    let articleCount = ref(0);
    onCurrentPageChanged(1);
    function onCurrentPageChanged(pageNum) {
      getPostArticleList(pageNum, pageSize, null, null, props.year + "/" + props.month).then(data => {
        articleCount.value = parseInt(data.total);
        data.rows.forEach(article => {
          article.createTime = article.createTime.split(" ")[0];
          article.thumbnail = article.thumbnail || defaultThumbnail;
        });
        postArticles.splice(0, postArticles.length, ...data.rows);
      });
    }
    window.scrollTo({
      top: 0
    });
    return {
      postArticles,
      articleCount,
      pageSize,
      onCurrentPageChanged
    };
  },
  props: ["year", "month"]
};