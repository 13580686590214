import "core-js/modules/es.array.push.js";
import { mapState } from "../../store/map";
import { getArchiveList } from "../../api/archive";
import { computed, reactive } from "vue";
import { defaultThumbnail, useDefaultThumbnail } from "../../utils/thumbnail";
export default {
  name: "ArchiveList",
  setup() {
    window.scrollTo({
      top: 0
    });
    let pageSize = 10;
    let archives = reactive([]);
    let {
      articleCountInfo
    } = mapState("adminAbout");
    let articleCount = computed(() => parseInt(articleCountInfo.value.article));
    onCurrentPageChanged(1);
    function onCurrentPageChanged(pageNum) {
      getArchiveList(pageNum, pageSize).then(data => {
        data.rows.forEach(archive => {
          archive.articles.forEach(article => {
            article.createTime = article.createTime.split(" ")[0];
            article.thumbnail = article.thumbnail || defaultThumbnail;
          });
        });
        archives.splice(0, archives.length, ...data.rows);

        // 多插入一个啥也没有的节点
        archives.push({
          year: "",
          articles: []
        });
      });
    }
    return {
      articleCount,
      pageSize,
      archives,
      onCurrentPageChanged,
      useDefaultThumbnail
    };
  }
};