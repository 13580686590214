import { mapState } from "../store/map";
export default {
  name: "KilaKilaCategoryCard",
  setup() {
    let {
      categoryCounts
    } = mapState("categoryAbout");
    return {
      categoryCounts
    };
  }
};