import { renderSlot as _renderSlot, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_button = _resolveComponent("el-button");
  return _openBlock(), _createBlock(_component_el_button, {
    color: "#1890ff",
    style: {
      "color": "white"
    },
    class: "kilakila-button"
  }, {
    default: _withCtx(() => [_renderSlot(_ctx.$slots, "default", {}, () => [_cache[0] || (_cache[0] = _createTextVNode("这是一个亮晶晶的按钮"))], true)]),
    _: 3
  });
}