import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  class: "header-menu"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_link = _resolveComponent("router-link");
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon");
  const _component_kila_kila_admin_menu = _resolveComponent("kila-kila-admin-menu");
  const _component_el_drawer = _resolveComponent("el-drawer");
  return _openBlock(), _createElementBlock("header", null, [_createVNode(_component_router_link, {
    class: "header-title",
    to: "/"
  }, {
    default: _withCtx(() => _cache[2] || (_cache[2] = [_createTextVNode("HadLuo的个人博客")])),
    _: 1
  }), _createElementVNode("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.menuItems, item => {
    return _openBlock(), _createElementBlock("div", {
      key: item.name
    }, [_createVNode(_component_router_link, {
      to: item.href,
      class: "header-menu-item"
    }, {
      default: _withCtx(() => [_createVNode(_component_font_awesome_icon, {
        icon: item.icon,
        class: "header-icon"
      }, null, 8, ["icon"]), _createTextVNode(_toDisplayString(item.name), 1)]),
      _: 2
    }, 1032, ["to"])]);
  }), 128))]), _createElementVNode("div", {
    id: "header-menu-button",
    onClick: _cache[0] || (_cache[0] = $event => $setup.drawer = !$setup.drawer)
  }, [_createVNode(_component_font_awesome_icon, {
    icon: ['fas', 'bars']
  })]), _createVNode(_component_el_drawer, {
    modelValue: $setup.drawer,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $setup.drawer = $event),
    direction: "ltr",
    "show-close": false,
    "with-header": false,
    size: "40%"
  }, {
    default: _withCtx(() => [_createVNode(_component_kila_kila_admin_menu)]),
    _: 1
  }, 8, ["modelValue"])]);
}