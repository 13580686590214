import "core-js/modules/es.array.push.js";
import { reactive } from "vue";
import { useDefaultThumbnail } from "../utils/thumbnail";
export default {
  name: "KilaKilaPostArticleCard",
  props: {
    article: {
      type: Object,
      require: true
    },
    reverse: {
      type: Boolean,
      require: false
    }
  },
  setup(props) {
    let cardClass = reactive(["post-article-card"]);
    let thumbailLinkClass = reactive(["post-article-thumbail-link"]);
    if (props.reverse) {
      cardClass.push("post-article-card-reversed");
      thumbailLinkClass.push("post-article-thumbail-link-reversed");
    }
    return {
      cardClass,
      thumbailLinkClass,
      useDefaultThumbnail
    };
  }
};