import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "card"
};
const _hoisted_2 = {
  class: "card-header"
};
const _hoisted_3 = {
  class: "card-body archive-list"
};
const _hoisted_4 = {
  class: "archive-date"
};
const _hoisted_5 = {
  class: "archive-count"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon");
  const _component_router_link = _resolveComponent("router-link");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", null, [_createElementVNode("span", null, [_createVNode(_component_font_awesome_icon, {
    icon: ['fas', 'box-archive'],
    class: "header-icon"
  })]), _cache[0] || (_cache[0] = _createElementVNode("span", null, "归档", -1))]), _createVNode(_component_router_link, {
    to: "/archive/",
    class: "header-more-link",
    title: "查看更多"
  }, {
    default: _withCtx(() => [_createVNode(_component_font_awesome_icon, {
      icon: ['fas', 'angle-right'],
      class: "header-more-icon"
    })]),
    _: 1
  })]), _createElementVNode("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.archiveCounts, archive => {
    return _openBlock(), _createBlock(_component_router_link, {
      key: archive.date,
      to: `/archive/${archive.date}`,
      class: "archive-item"
    }, {
      default: _withCtx(() => [_createElementVNode("span", _hoisted_4, _toDisplayString(archive.date.split("/")[0]) + " 年 " + _toDisplayString(archive.date.split("/")[1]) + " 月 ", 1), _createElementVNode("span", _hoisted_5, _toDisplayString(archive.count), 1)]),
      _: 2
    }, 1032, ["to"]);
  }), 128))])]);
}