import { mapState } from "../../store/map";
export default {
  name: "CategoryList",
  setup() {
    window.scrollTo({
      top: 0
    });
    let {
      categoryCounts
    } = mapState("categoryAbout");
    return {
      categoryCounts
    };
  }
};