import "core-js/modules/es.array.push.js";
import { reactive } from "@vue/reactivity";
import { getArchiveCountList } from "../api/archive";
export default {
  name: "KilaKilaArchiveCard",
  setup() {
    let archiveCounts = reactive([]);
    getArchiveCountList(1, 100).then(data => {
      archiveCounts.push(...data.rows);
    });
    return {
      archiveCounts
    };
  }
};