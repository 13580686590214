import "core-js/modules/es.array.push.js";
import { getArticleDetails, getPreviousNextArticle, updateViewCount } from "../../api/article";
import { reactive, nextTick, ref } from "vue";
import { ElMessage } from "element-plus";
import markdownIt from "../../utils/markdown-it";
import { mapState } from "../../store/map";
import { useDefaultThumbnail, defaultThumbnail } from "../../utils/thumbnail";
import buildCodeBlock from "../../utils/code-block";
import { renderByMathjax, initMathJax } from "../../utils/mathjax";
import router from "../../router";
import { getCommentList, addComment, deleteComment, updateComment } from "../../api/comment";
import { getUserInfo } from "../../utils/storage";
export default {
  name: "ArticleDetails",
  setup(props) {
    window.scrollTo({
      top: 0
    });
    let {
      adminInfo,
      isAdmin
    } = mapState("adminAbout");
    let articleLoaded = ref(false);
    let articleUrl = ref(window.location.href);
    let previousArticle = reactive({});
    let nextArticle = reactive({});
    let lightBoxRef = ref();

    // 获取文章内容
    let articleDetails = reactive({
      createTime: ""
    });
    getArticleDetails(props.id).then(data => {
      Object.assign(articleDetails, data);
      articleDetails.content = markdownIt.render(data.content);
      nextTick(() => {
        initMathJax({}, () => {
          renderByMathjax(".article-content");
          renderByMathjax(".comment-item-content");
        });
        buildCodeBlock(".article-content");
        articleLoaded.value = true;
      }).then(() => {
        lightBoxRef.value.addImageClickedListener();
      });
    });
    updateViewCount(props.id);

    // 获取上一篇和下一篇文章
    getPreviousNextArticle(props.id).then(data => {
      if (data.previous) {
        Object.assign(previousArticle, data.previous);
        if (!previousArticle.thumbnail) {
          previousArticle.thumbnail = defaultThumbnail;
        }
      }
      if (data.next) {
        Object.assign(nextArticle, data.next);
        if (!nextArticle.thumbnail) {
          nextArticle.thumbnail = defaultThumbnail;
        }
      }
    });

    // 获取评论列表
    let comments = reactive([]);
    let commentCount = ref(0);
    let commentPageSize = 5;
    let currentCommentPageNum = ref(1);
    onCurrentCommentPageChanged(1);
    function onCurrentCommentPageChanged(pageNum) {
      getCommentList(props.id, pageNum, commentPageSize).then(data => {
        currentCommentPageNum.value = pageNum;
        commentCount.value = parseInt(data.total);
        comments.splice(0, comments.length, ...data.rows);
        nextTick(() => {
          renderByMathjax(".comment-item-content");
          buildCodeBlock(".comment-item-content");
        });
      });
    }
    function editArticle() {
      router.push(`/article/${props.id}/edit`);
    }

    // 添加评论
    let mavonToolbarOption = {
      bold: true,
      // 粗体
      italic: true,
      // 斜体
      header: true,
      // 标题
      underline: true,
      // 下划线
      strikethrough: true,
      // 中划线
      mark: true,
      // 标记
      superscript: true,
      // 上角标
      subscript: true,
      // 下角标
      quote: true,
      // 引用
      ol: true,
      // 有序列表
      ul: true,
      // 无序列表
      link: true,
      // 链接
      imagelink: true,
      // 图片链接
      code: true,
      // code
      table: true,
      // 表格
      fullscreen: true,
      // 全屏编辑
      help: true,
      // 帮助
      navigation: true,
      // 导航目录
      alignleft: true,
      // 左对齐
      aligncenter: true,
      // 居中
      alignright: true,
      // 右对齐
      subfield: true,
      // 单双栏模式
      preview: true // 预览
    };
    let commentContent = ref("");
    let isInEditMode = ref(false);
    let editedComment = {};
    let mavonRef = ref();
    function onImageAdded(pos, file) {
      uploadImage(file).then(url => {
        mavonRef.value.$img2Url(pos, url);
      });
    }
    function submitComment() {
      if (commentContent.value.trim().length == 0) {
        ElMessage.warning("评论内容不能为空哦~");
        return;
      }
      if (!isInEditMode.value) {
        var promise = addComment(props.id, commentContent.value);
      } else {
        var promise = updateComment(editedComment.id, commentContent.value);
      }
      promise.then(() => {
        ElMessage.success("吐槽成功啦");
        commentContent.value = "";
        onCurrentCommentPageChanged(currentCommentPageNum.value);
      });
      isInEditMode.value = false;
    }
    function onReplyComment(comment) {
      commentContent.value = `@${comment.userName}\n>${comment.content.replace(/\n/g, "\n>")}\n\n`;
    }
    function onDeleteComment(comment) {
      ElMessageBox.confirm("删除评论确认？", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        deleteComment(comment.id).then(() => {
          ElMessage.success("删除成功！");
          onCurrentCommentPageChanged(0);
        });
      });
    }
    function onUpdateComment(comment) {
      commentContent.value = comment.content;
      isInEditMode.value = true;
      editedComment = comment;
    }
    return {
      isAdmin,
      articleDetails,
      articleLoaded,
      adminInfo,
      articleUrl,
      comments,
      commentCount,
      commentPageSize,
      currentCommentPageNum,
      useDefaultThumbnail,
      onCurrentCommentPageChanged,
      previousArticle,
      nextArticle,
      lightBoxRef,
      mavonToolbarOption,
      commentContent,
      mavonRef,
      isInEditMode,
      editArticle,
      onImageAdded,
      submitComment,
      onDeleteComment,
      onReplyComment,
      onUpdateComment
    };
  },
  props: ["id"]
};