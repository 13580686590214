import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "page_404"
};
const _hoisted_2 = {
  class: "container"
};
const _hoisted_3 = {
  class: "contant_box_404"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_link = _resolveComponent("router-link");
  return _openBlock(), _createElementBlock("section", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_cache[3] || (_cache[3] = _createElementVNode("div", {
    class: "four_zero_four_bg"
  }, [_createElementVNode("h1", {
    class: "text-center"
  }, "404")], -1)), _createElementVNode("div", _hoisted_3, [_cache[1] || (_cache[1] = _createElementVNode("h3", {
    class: "h2"
  }, "啊哦，出错啦", -1)), _cache[2] || (_cache[2] = _createElementVNode("p", null, "你寻找的页面走丢啦!", -1)), _createVNode(_component_router_link, {
    to: "/",
    class: "link_404"
  }, {
    default: _withCtx(() => _cache[0] || (_cache[0] = [_createTextVNode("返回主页")])),
    _: 1
  })])])]);
}