import "core-js/modules/es.array.push.js";
import { getHotArticleList } from "../api/article";
import { reactive } from "@vue/reactivity";
import { defaultThumbnail, useDefaultThumbnail } from "../utils/thumbnail";
export default {
  name: "KilaKilaHotArticleCard",
  setup() {
    let hotArticles = reactive([]);
    getHotArticleList().then(data => {
      data.forEach(article => {
        article.createTime = article.createTime.split(" ")[0];
        article.thumbnail = article.thumbnail || defaultThumbnail;
      });
      hotArticles.push(...data);
    });
    return {
      hotArticles,
      useDefaultThumbnail
    };
  }
};