import "core-js/modules/es.array.push.js";
import { ref, reactive, computed, nextTick } from "vue";
import { mapState } from "../../store/map";
import { ElMessage, ElMessageBox } from "element-plus";
import { addArticle, editArticle, getArticleDetails } from "../../api/article";
import { uploadImage } from "../../api/image";
import router from "../../router";
import bus from "../../utils/bus";
import markdownIt from "../../utils/markdown-it";
export default {
  name: "ArticleEdit",
  setup(props) {
    let isInEditMode = props.id ? true : false;
    let content = ref("");
    let mavonRef = ref();
    let ruleFormRef = ref();
    let uploaderRef = ref();
    let title = computed(() => isInEditMode ? "编辑博客" : "新随笔");
    let {
      categoryCounts
    } = mapState("categoryAbout");
    let {
      tagCounts
    } = mapState("tagAbout");
    let categories = computed(() => {
      return categoryCounts.value.map(i => ({
        value: i.name,
        label: i.name
      }));
    });
    let tags = computed(() => {
      return tagCounts.value.map(i => ({
        value: i.name,
        label: i.name
      }));
    });
    let ruleForm = reactive({
      id: undefined,
      title: "",
      summary: "",
      content: "",
      category: "",
      tags: [],
      thumbnail: "",
      isDraft: false
    });
    let rules = reactive({
      title: [{
        required: true,
        message: "文章标题不能为空",
        trigger: "change"
      }],
      content: [{
        required: true,
        message: "文章内容不能为空",
        trigger: "change"
      }],
      category: [{
        required: true,
        message: "分类不能为空",
        trigger: "change"
      }]
    });

    // 如果有传入文章 id 需要先获取文章信息
    if (isInEditMode) {
      getArticleDetails(props.id).then(data => {
        Object.assign(ruleForm, data);
        ruleForm.category = data.categoryName;
        ruleForm.tags = data.tags.map(t => t.name);
        if (data.thumbnail) {
          uploaderRef.value.setImageUrl(data.thumbnail);
          uploaderRef.value.isSuccessLabelVisible = true;
        }
      });
    }
    function onImageAdded(pos, file) {
      uploadImage(file).then(url => {
        mavonRef.value.$img2Url(pos, url);
      });
    }
    function handleThumbnailUploaded(url) {
      ruleForm.thumbnail = url;
      document.getElementById("submit-button").disabled = false;
      document.getElementById("draft-button").disabled = false;
    }
    function handleAboutToUploadThumbnail() {
      document.getElementById("submit-button").disabled = true;
      document.getElementById("draft-button").disabled = true;
    }
    function handleRemoveThumbnail(file) {
      ruleForm.thumbnail = "";
    }
    function submitForm(form, isDraft) {
      if (!validateForm(form)) return;
      ruleForm.isDraft = isDraft;
      generateSummary();
      let name = isDraft ? "草稿" : "博客";
      if (!isInEditMode) {
        addArticle(ruleForm).then(id => {
          ElMessage.success(name + "保存成功啦");
          setTimeout(() => {
            router.push("/article/" + id);
          }, 1500);
        });
      } else {
        editArticle(ruleForm).then(id => {
          ElMessage.success(name + "编辑成功啦");
          bus.emit("articlePosted");
          setTimeout(() => {
            router.push("/article/" + id);
          }, 1500);
        });
      }
    }
    function cancelSubmit() {
      ElMessageBox.confirm("确认取消发布？", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        router.push("/");
      });
    }
    function validateForm(form) {
      if (!form) return false;
      form.validate(valid => {
        if (!valid) {
          ElMessage.error("必填字段不能为空哦");
          return false;
        }
      });
      return true;
    }
    function generateSummary() {
      if (ruleForm.summary) {
        return;
      }
      let html = markdownIt.render(ruleForm.content);
      ruleForm.summary = html.replace(/<[^>]+>/g, "").slice(0, 150);
    }
    nextTick(() => {
      window.scrollTo({
        top: 0
      });
    });
    return {
      title,
      content,
      ruleForm,
      ruleFormRef,
      uploaderRef,
      rules,
      categories,
      tags,
      onImageAdded,
      mavonRef,
      handleThumbnailUploaded,
      handleAboutToUploadThumbnail,
      handleRemoveThumbnail,
      submitForm,
      cancelSubmit
    };
  },
  props: ["id"]
};