import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  id: "category-list"
};
const _hoisted_2 = {
  class: "container"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_kila_kila_header = _resolveComponent("kila-kila-header");
  const _component_kila_kila_wife_cover = _resolveComponent("kila-kila-wife-cover");
  const _component_kila_kila_side_bar = _resolveComponent("kila-kila-side-bar");
  const _component_kila_kila_word_cloud_card = _resolveComponent("kila-kila-word-cloud-card");
  const _component_kila_kila_footer = _resolveComponent("kila-kila-footer");
  const _component_kila_kila_back_to_top = _resolveComponent("kila-kila-back-to-top");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_kila_kila_header), _createVNode(_component_kila_kila_wife_cover, null, {
    default: _withCtx(() => _cache[0] || (_cache[0] = [_createElementVNode("h1", null, "分类", -1)])),
    _: 1
  }), _createElementVNode("div", _hoisted_2, [_createVNode(_component_kila_kila_side_bar), _createVNode(_component_kila_kila_word_cloud_card, {
    words: $setup.categoryCounts,
    baseUrl: "/category"
  }, null, 8, ["words"])]), _createVNode(_component_kila_kila_footer), _createVNode(_component_kila_kila_back_to_top)]);
}