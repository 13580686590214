import "core-js/modules/es.array.push.js";
import { ref, reactive } from "vue";
import { User, Lock } from "@element-plus/icons-vue";
import { ElMessage } from "element-plus";
import { login } from "../api/user";
import { setToken, setUserInfo, removeToken, removeUserInfo } from "../utils/storage";
import { md5Encryption } from "../utils/encrypt";
import router from "../router/index";
import store from "../store";
export default {
  name: "Login",
  components: {
    User,
    Lock
  },
  setup() {
    removeToken();
    removeUserInfo();
    let ruleFormRef = ref();
    let ruleForm = reactive({
      userName: "",
      password: ""
    });
    let rules = reactive({
      userName: [{
        required: true,
        message: "用户名不能为空",
        trigger: "change"
      }],
      password: [{
        required: true,
        message: "密码不能为空",
        trigger: "change"
      }]
    });
    function submitForm(form) {
      if (!form) return;
      form.validate(valid => {
        if (!valid) {
          ElMessage.error("用户名和密码不能为空");
          return;
        }
        login(ruleForm.userName, ruleForm.password).then(data => {
          setToken(data.token);
          setUserInfo(data.userInfo);
          store.commit("adminAbout/updateIsAdmin");
          router.push("/");
        });
      });
    }
    return {
      rules,
      ruleForm,
      ruleFormRef,
      submitForm
    };
  }
};