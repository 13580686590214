import { ref, computed, onBeforeUnmount } from "vue";
import { useStore } from "vuex";
import { getRunTime } from "../utils/date";
export default {
  name: "KilaKilaFooter",
  setup() {
    let year = ref(new Date().getFullYear());
    let currentTime = ref(new Date().getTime());
    let timer = setInterval(() => {
      currentTime.value = new Date().getTime();
    }, 1000);
    let blogRunTime = computed(() => {
      let startDate = useStore().state.websiteAbout.startDate;
      let {
        day,
        hour,
        min,
        second
      } = getRunTime(startDate, currentTime.value);
      return `${day} 天 ${hour} 小时 ${min} 分 ${second} 秒`;
    });
    onBeforeUnmount(() => {
      clearInterval(timer);
    });
    return {
      year,
      blogRunTime
    };
  },
  props: {
    adminName: {
      type: String,
      require: true
    }
  }
};