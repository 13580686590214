import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "category-list"
};
const _hoisted_2 = {
  class: "category-name"
};
const _hoisted_3 = {
  class: "category-count"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_link = _resolveComponent("router-link");
  const _component_kila_kila_card = _resolveComponent("kila-kila-card");
  return _openBlock(), _createBlock(_component_kila_kila_card, {
    icon: ['fas', 'folder'],
    iconColor: "#fcd53f",
    title: "分类"
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.categoryCounts, category => {
      return _openBlock(), _createBlock(_component_router_link, {
        key: category.id,
        to: `/category/${category.id}`,
        class: "category-item"
      }, {
        default: _withCtx(() => [_createElementVNode("span", _hoisted_2, _toDisplayString(category.name), 1), _createElementVNode("span", _hoisted_3, _toDisplayString(category.count), 1)]),
        _: 2
      }, 1032, ["to"]);
    }), 128))])]),
    _: 1
  });
}