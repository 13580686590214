import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "admin-menu"
};
const _hoisted_2 = {
  class: "link-name"
};
const _hoisted_3 = {
  class: "link-name"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_kila_kila_admin_card = _resolveComponent("kila-kila-admin-card");
  const _component_kila_kila_divider = _resolveComponent("kila-kila-divider");
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon");
  const _component_el_menu_item = _resolveComponent("el-menu-item");
  const _component_el_sub_menu = _resolveComponent("el-sub-menu");
  const _component_el_menu = _resolveComponent("el-menu");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_kila_kila_admin_card), _createVNode(_component_kila_kila_divider), _createVNode(_component_el_menu, null, {
    default: _withCtx(() => [_createVNode(_component_el_menu_item, {
      index: "1",
      onClick: _cache[0] || (_cache[0] = $event => _ctx.$router.push('/'))
    }, {
      default: _withCtx(() => [_createVNode(_component_font_awesome_icon, {
        icon: ['fab', 'fort-awesome'],
        class: "menu-icon"
      }), _cache[4] || (_cache[4] = _createElementVNode("span", null, "首页", -1))]),
      _: 1
    }), _createVNode(_component_el_menu_item, {
      index: "2",
      onClick: _cache[1] || (_cache[1] = $event => _ctx.$router.push('/archive'))
    }, {
      default: _withCtx(() => [_createVNode(_component_font_awesome_icon, {
        icon: ['fas', 'box-archive'],
        class: "menu-icon"
      }), _cache[5] || (_cache[5] = _createElementVNode("span", null, "归档", -1))]),
      _: 1
    }), _createVNode(_component_el_sub_menu, {
      index: "3"
    }, {
      title: _withCtx(() => [_createVNode(_component_font_awesome_icon, {
        icon: ['fas', 'folder'],
        class: "menu-icon"
      }), _cache[6] || (_cache[6] = _createElementVNode("span", null, "分类", -1))]),
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$store.state.categoryAbout.categoryCounts, category => {
        return _openBlock(), _createBlock(_component_el_menu_item, {
          key: category.id,
          index: '3-' + category.id,
          class: "link-item",
          onClick: $event => _ctx.$router.push('/category/' + category.id)
        }, {
          default: _withCtx(() => [_cache[7] || (_cache[7] = _createTextVNode("🗂️ ")), _createElementVNode("span", _hoisted_2, _toDisplayString(category.name), 1), _createElementVNode("span", null, _toDisplayString(category.count), 1)]),
          _: 2
        }, 1032, ["index", "onClick"]);
      }), 128))]),
      _: 1
    }), _createVNode(_component_el_sub_menu, {
      index: "4"
    }, {
      title: _withCtx(() => [_createVNode(_component_font_awesome_icon, {
        icon: ['fas', 'tags'],
        class: "menu-icon"
      }), _cache[8] || (_cache[8] = _createElementVNode("span", null, "标签", -1))]),
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$store.state.tagAbout.tagCounts, tag => {
        return _openBlock(), _createBlock(_component_el_menu_item, {
          key: tag.id,
          index: '4-' + tag.id,
          class: "link-item",
          onClick: $event => _ctx.$router.push('/tag/' + tag.id)
        }, {
          default: _withCtx(() => [_cache[9] || (_cache[9] = _createTextVNode("🔖 ")), _createElementVNode("span", _hoisted_3, _toDisplayString(tag.name), 1), _createElementVNode("span", null, _toDisplayString(tag.count), 1)]),
          _: 2
        }, 1032, ["index", "onClick"]);
      }), 128))]),
      _: 1
    }), _ctx.$store.state.adminAbout.isAdmin ? (_openBlock(), _createBlock(_component_el_menu_item, {
      key: 0,
      index: "5",
      onClick: _cache[2] || (_cache[2] = $event => _ctx.$router.push('/article/add'))
    }, {
      default: _withCtx(() => [_createVNode(_component_font_awesome_icon, {
        icon: ['fas', 'pen'],
        class: "menu-icon"
      }), _cache[10] || (_cache[10] = _createElementVNode("span", null, "新随笔", -1))]),
      _: 1
    })) : _createCommentVNode("", true), _createVNode(_component_el_menu_item, {
      index: "6",
      onClick: _cache[3] || (_cache[3] = $event => _ctx.$router.push('/user/settings'))
    }, {
      default: _withCtx(() => [_createVNode(_component_font_awesome_icon, {
        icon: ['fas', 'user'],
        class: "menu-icon"
      }), _cache[11] || (_cache[11] = _createElementVNode("span", null, "个人中心", -1))]),
      _: 1
    })]),
    _: 1
  })]);
}