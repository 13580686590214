import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue";
const _hoisted_1 = ["src"];
const _hoisted_2 = {
  class: "hot-article-info"
};
const _hoisted_3 = {
  class: "hot-article-meta-data"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_link = _resolveComponent("router-link");
  const _component_kila_kila_card = _resolveComponent("kila-kila-card");
  return _openBlock(), _createBlock(_component_kila_kila_card, {
    icon: ['fas', 'fire'],
    iconColor: "#f01111",
    title: "热门文章"
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.hotArticles, article => {
      return _openBlock(), _createElementBlock("div", {
        key: article.id,
        class: "hot-article-item"
      }, [_createVNode(_component_router_link, {
        to: `/article/${article.id}`,
        class: "hot-article-thumbail-link"
      }, {
        default: _withCtx(() => [_createElementVNode("img", {
          src: article.thumbnail,
          onErrorOnce: _cache[0] || (_cache[0] = (...args) => $setup.useDefaultThumbnail && $setup.useDefaultThumbnail(...args)),
          alt: "缩略图",
          class: "hot-article-thumbnail"
        }, null, 40, _hoisted_1)]),
        _: 2
      }, 1032, ["to"]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_router_link, {
        to: `/article/${article.id}`,
        class: "hot-article-title"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(article.title), 1)]),
        _: 2
      }, 1032, ["to"]), _createElementVNode("div", _hoisted_3, [_createTextVNode(_toDisplayString(article.createTime) + " ", 1), _createElementVNode("span", null, _toDisplayString(article.viewCount) + "次围观", 1)])])]);
    }), 128))]),
    _: 1
  });
}