import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  id: "archive-list"
};
const _hoisted_2 = {
  class: "container"
};
const _hoisted_3 = {
  class: "archive-body"
};
const _hoisted_4 = {
  class: "archive-card"
};
const _hoisted_5 = ["src"];
const _hoisted_6 = {
  class: "article-info"
};
const _hoisted_7 = {
  class: "article-meta-data"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_kila_kila_header = _resolveComponent("kila-kila-header");
  const _component_kila_kila_wife_cover = _resolveComponent("kila-kila-wife-cover");
  const _component_kila_kila_side_bar = _resolveComponent("kila-kila-side-bar");
  const _component_el_timeline_item = _resolveComponent("el-timeline-item");
  const _component_router_link = _resolveComponent("router-link");
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon");
  const _component_el_timeline = _resolveComponent("el-timeline");
  const _component_el_pagination = _resolveComponent("el-pagination");
  const _component_kila_kila_footer = _resolveComponent("kila-kila-footer");
  const _component_kila_kila_back_to_top = _resolveComponent("kila-kila-back-to-top");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_kila_kila_header), _createVNode(_component_kila_kila_wife_cover, null, {
    default: _withCtx(() => _cache[1] || (_cache[1] = [_createElementVNode("h1", null, "归档", -1)])),
    _: 1
  }), _createElementVNode("div", _hoisted_2, [_createVNode(_component_kila_kila_side_bar), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createVNode(_component_el_timeline, {
    class: "timeline"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_timeline_item, {
      center: "",
      timestamp: `历史文章 - ${$setup.articleCount}`,
      placement: "top",
      class: "root-item"
    }, null, 8, ["timestamp"]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.archives, archive => {
      return _openBlock(), _createBlock(_component_el_timeline_item, {
        key: archive.year,
        class: "year-item",
        timestamp: archive.year + '',
        placement: "top"
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(archive.articles, article => {
          return _openBlock(), _createElementBlock("div", {
            key: article.id,
            class: "article-item"
          }, [_createVNode(_component_router_link, {
            to: `/article/${article.id}`,
            class: "article-thumbail-link"
          }, {
            default: _withCtx(() => [_createElementVNode("img", {
              src: article.thumbnail,
              onErrorOnce: _cache[0] || (_cache[0] = (...args) => $setup.useDefaultThumbnail && $setup.useDefaultThumbnail(...args)),
              alt: "缩略图",
              class: "article-thumbnail"
            }, null, 40, _hoisted_5)]),
            _: 2
          }, 1032, ["to"]), _createElementVNode("div", _hoisted_6, [_createVNode(_component_router_link, {
            to: `/article/${article.id}`,
            class: "article-title"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(article.title), 1)]),
            _: 2
          }, 1032, ["to"]), _createElementVNode("div", _hoisted_7, [_createElementVNode("span", null, [_createVNode(_component_font_awesome_icon, {
            icon: ['fas', 'calendar-days'],
            class: "article-meta-data-icon"
          }), _createTextVNode("发表于 " + _toDisplayString(article.createTime), 1)]), _createElementVNode("span", null, [_createVNode(_component_font_awesome_icon, {
            icon: ['fas', 'eye'],
            class: "article-meta-data-icon"
          }), _createTextVNode(_toDisplayString(article.viewCount) + "次围观", 1)])])])]);
        }), 128))]),
        _: 2
      }, 1032, ["timestamp"]);
    }), 128))]),
    _: 1
  })]), $setup.articleCount > 0 ? (_openBlock(), _createBlock(_component_el_pagination, {
    key: 0,
    background: "",
    layout: "prev, pager, next",
    total: $setup.articleCount,
    "page-size": $setup.pageSize,
    id: "pagination",
    onCurrentChange: $setup.onCurrentPageChanged
  }, null, 8, ["total", "page-size", "onCurrentChange"])) : _createCommentVNode("", true)])]), _createVNode(_component_kila_kila_footer), _createVNode(_component_kila_kila_back_to_top)]);
}