import { mapState } from "../store/map";
import { linearColorWordCloud } from "../utils/word-cloud";
import { computed } from "@vue/runtime-core";
export default {
  name: "KilaKilaTagCard",
  setup() {
    let {
      tagCounts
    } = mapState("tagAbout");
    let tagClouds = computed(() => linearColorWordCloud(tagCounts.value));
    return {
      tagClouds
    };
  }
};