import { reactive } from "@vue/reactivity";
export default {
  name: "KilaKilaCard",
  props: {
    title: {
      type: String,
      require: true
    },
    icon: {
      type: Array,
      require: true
    },
    iconColor: {
      type: String,
      require: false,
      default: "var(--text-color)"
    }
  },
  setup(props) {
    let headerIconStyle = reactive({
      color: props.iconColor
    });
    return {
      headerIconStyle
    };
  }
};