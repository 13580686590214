import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  id: "footer"
};
const _hoisted_2 = {
  class: "footer-box"
};
const _hoisted_3 = {
  class: "blog-run-time"
};
const _hoisted_4 = {
  class: "copyright-text"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", null, [_createElementVNode("span", _hoisted_3, "网站已经运行了 " + _toDisplayString($setup.blogRunTime), 1)]), _createElementVNode("div", _hoisted_4, "Copyright © 2022 " + _toDisplayString($props.adminName), 1)])]);
}