import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "catalog-card"
};
const _hoisted_2 = {
  class: "catalog-card-header"
};
const _hoisted_3 = {
  class: "progress"
};
const _hoisted_4 = {
  class: "catalog-content"
};
const _hoisted_5 = ["onClick", "title"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon");
  return Object.keys($setup.titles).length > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", null, [_createElementVNode("span", null, [_createVNode(_component_font_awesome_icon, {
    icon: ['fas', 'bars-staggered'],
    class: "catalog-icon"
  })]), _cache[0] || (_cache[0] = _createElementVNode("span", null, "目录", -1))]), _createElementVNode("span", _hoisted_3, _toDisplayString($setup.progress), 1)]), _createElementVNode("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.titles, title => {
    return _withDirectives((_openBlock(), _createElementBlock("div", {
      key: title.id,
      onClick: $event => $setup.scrollToView(title.scrollTop),
      class: _normalizeClass(['catalog-item', $setup.currentTitle.id == title.id ? 'active' : 'not-active']),
      style: _normalizeStyle({
        marginLeft: title.level * 20 + 'px'
      }),
      title: title.rawName
    }, _toDisplayString(title.name), 15, _hoisted_5)), [[_vShow, title.isVisible]]);
  }), 128))])])) : _createCommentVNode("", true);
}