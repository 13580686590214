import { mapState } from "../../store/map";
export default {
  name: "TagList",
  setup() {
    window.scrollTo({
      top: 0
    });
    let {
      tagCounts
    } = mapState("tagAbout");
    return {
      tagCounts
    };
  }
};