import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    id: "back-to-top",
    class: _normalizeClass($setup.buttonClass),
    onClick: _cache[0] || (_cache[0] = (...args) => $setup.scrollToTop && $setup.scrollToTop(...args))
  }, _cache[1] || (_cache[1] = [_createElementVNode("svg", {
    viewBox: "0 0 24 24"
  }, [_createElementVNode("path", {
    d: "M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z"
  })], -1)]), 2);
}