import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["src"];
const _hoisted_2 = {
  class: "post-article-info"
};
const _hoisted_3 = {
  class: "post-article-meta-data-wrap"
};
const _hoisted_4 = {
  class: "post-article-meta-data"
};
const _hoisted_5 = {
  class: "post-article-meta-data"
};
const _hoisted_6 = {
  class: "post-article-meta-data"
};
const _hoisted_7 = {
  class: "post-article-summary"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_link = _resolveComponent("router-link");
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass($setup.cardClass)
  }, [_createVNode(_component_router_link, {
    to: `/article/${$props.article.id}`,
    class: _normalizeClass($setup.thumbailLinkClass)
  }, {
    default: _withCtx(() => [_createElementVNode("img", {
      src: $props.article.thumbnail,
      onErrorOnce: _cache[0] || (_cache[0] = (...args) => $setup.useDefaultThumbnail && $setup.useDefaultThumbnail(...args)),
      alt: "缩略图",
      class: "post-article-thumbnail"
    }, null, 40, _hoisted_1)]),
    _: 1
  }, 8, ["to", "class"]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_router_link, {
    to: `/article/${$props.article.id}`,
    class: "post-article-title"
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString($props.article.title), 1)]),
    _: 1
  }, 8, ["to"]), _createElementVNode("div", _hoisted_3, [_createElementVNode("span", _hoisted_4, [_createVNode(_component_font_awesome_icon, {
    icon: ['fas', 'calendar-days']
  }), _createTextVNode(" 发表于 " + _toDisplayString($props.article.createTime), 1)]), _cache[1] || (_cache[1] = _createElementVNode("span", {
    class: "post-article-meta-data-divider"
  }, "|", -1)), _createElementVNode("span", _hoisted_5, [_createVNode(_component_font_awesome_icon, {
    icon: ['fas', 'box-archive']
  }), _createTextVNode(" 分类 " + _toDisplayString($props.article.categoryName), 1)]), _cache[2] || (_cache[2] = _createElementVNode("span", {
    class: "post-article-meta-data-divider"
  }, "|", -1)), _createElementVNode("span", _hoisted_6, [_createVNode(_component_font_awesome_icon, {
    icon: ['fas', 'eye']
  }), _createTextVNode(" 阅读量 " + _toDisplayString($props.article.viewCount), 1)])]), _createElementVNode("div", _hoisted_7, _toDisplayString($props.article.summary), 1)])], 2);
}