import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue";
const _hoisted_1 = {
  class: "uploader"
};
const _hoisted_2 = {
  class: "card thumbnail-card"
};
const _hoisted_3 = {
  class: "success-label"
};
const _hoisted_4 = {
  class: "thumbnail-actions"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_progress = _resolveComponent("el-progress");
  const _component_vue_easy_lightbox = _resolveComponent("vue-easy-lightbox");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("input", {
    type: "file",
    id: "file-input",
    style: {
      "display": "none"
    },
    accept: "image/*",
    onChange: _cache[0] || (_cache[0] = (...args) => $setup.onImageAdded && $setup.onImageAdded(...args))
  }, null, 32), !$setup.isThumbnailVisible ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: "card upload-card",
    onClick: _cache[1] || (_cache[1] = (...args) => $setup.openFileDialog && $setup.openFileDialog(...args))
  }, _cache[4] || (_cache[4] = [_createElementVNode("svg", {
    class: "icon",
    width: "28",
    height: "28",
    viewBox: "0 0 1024 1024",
    xmlns: "http://www.w3.org/2000/svg"
  }, [_createElementVNode("path", {
    fill: "#8c939d",
    d: "M480 480V128a32 32 0 0164 0v352h352a32 32 0 110 64H544v352a32 32 0 11-64 0V544H128a32 32 0 010-64h352z"
  })], -1)]))) : _createCommentVNode("", true), _withDirectives(_createElementVNode("div", _hoisted_2, [_cache[8] || (_cache[8] = _createElementVNode("img", {
    src: "",
    alt: "缩略图",
    id: "thumbnail"
  }, null, -1)), _withDirectives(_createElementVNode("label", _hoisted_3, _cache[5] || (_cache[5] = [_createElementVNode("i", {
    class: "success-icon"
  }, [_createElementVNode("svg", {
    class: "icon",
    width: "12",
    height: "12",
    viewBox: "0 0 1024 1024",
    xmlns: "http://www.w3.org/2000/svg"
  }, [_createElementVNode("path", {
    fill: "white",
    d: "M406.656 706.944L195.84 496.256a32 32 0 10-45.248 45.248l256 256 512-512a32 32 0 00-45.248-45.248L406.592 706.944z"
  })])], -1)]), 512), [[_vShow, $setup.isSuccessLabelVisible]]), _createElementVNode("div", _hoisted_4, [_createElementVNode("span", {
    class: "thumbnail-preview",
    onClick: _cache[2] || (_cache[2] = (...args) => $setup.handleThumbnailPreview && $setup.handleThumbnailPreview(...args))
  }, _cache[6] || (_cache[6] = [_createElementVNode("svg", {
    class: "icon",
    width: "20",
    height: "20",
    viewBox: "0 0 1024 1024",
    xmlns: "http://www.w3.org/2000/svg"
  }, [_createElementVNode("path", {
    fill: "white",
    d: "M795.904 750.72l124.992 124.928a32 32 0 01-45.248 45.248L750.656 795.904a416 416 0 1145.248-45.248zM480 832a352 352 0 100-704 352 352 0 000 704zm-32-384v-96a32 32 0 0164 0v96h96a32 32 0 010 64h-96v96a32 32 0 01-64 0v-96h-96a32 32 0 010-64h96z"
  })], -1)])), _createElementVNode("span", {
    class: "thumbnail-delete",
    onClick: _cache[3] || (_cache[3] = (...args) => $setup.handleThumbnailRemove && $setup.handleThumbnailRemove(...args))
  }, _cache[7] || (_cache[7] = [_createElementVNode("svg", {
    class: "icon",
    width: "20",
    height: "20",
    viewBox: "0 0 1024 1024",
    xmlns: "http://www.w3.org/2000/svg"
  }, [_createElementVNode("path", {
    fill: "white",
    d: "M160 256H96a32 32 0 010-64h256V95.936a32 32 0 0132-32h256a32 32 0 0132 32V192h256a32 32 0 110 64h-64v672a32 32 0 01-32 32H192a32 32 0 01-32-32V256zm448-64v-64H416v64h192zM224 896h576V256H224v640zm192-128a32 32 0 01-32-32V416a32 32 0 0164 0v320a32 32 0 01-32 32zm192 0a32 32 0 01-32-32V416a32 32 0 0164 0v320a32 32 0 01-32 32z"
  })], -1)]))]), _withDirectives(_createVNode(_component_el_progress, {
    type: "circle",
    percentage: $setup.progress,
    width: 110,
    id: "progress"
  }, null, 8, ["percentage"]), [[_vShow, $setup.isProgressVisible]])], 512), [[_vShow, $setup.isThumbnailVisible]]), _createVNode(_component_vue_easy_lightbox, {
    moveDisabled: "",
    visible: $setup.isLightBoxVisible,
    imgs: $setup.localImageUrl,
    index: $setup.index,
    onHide: $setup.handleLightboxHide
  }, null, 8, ["visible", "imgs", "index", "onHide"])]);
}