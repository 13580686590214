import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  id: "category-details"
};
const _hoisted_2 = {
  class: "category-info"
};
const _hoisted_3 = {
  class: "category-name"
};
const _hoisted_4 = {
  class: "container"
};
const _hoisted_5 = {
  class: "post-article-list"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_kila_kila_header = _resolveComponent("kila-kila-header");
  const _component_kila_kila_wife_cover = _resolveComponent("kila-kila-wife-cover");
  const _component_kila_kila_side_bar = _resolveComponent("kila-kila-side-bar");
  const _component_kila_kila_post_article_card = _resolveComponent("kila-kila-post-article-card");
  const _component_el_pagination = _resolveComponent("el-pagination");
  const _component_kila_kila_footer = _resolveComponent("kila-kila-footer");
  const _component_kila_kila_back_to_top = _resolveComponent("kila-kila-back-to-top");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_kila_kila_header), _createVNode(_component_kila_kila_wife_cover, null, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createElementVNode("h1", _hoisted_3, _toDisplayString($setup.categoryName), 1)])]),
    _: 1
  }), _createElementVNode("div", _hoisted_4, [_createVNode(_component_kila_kila_side_bar), _createElementVNode("div", _hoisted_5, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.postArticles, (article, index) => {
    return _openBlock(), _createBlock(_component_kila_kila_post_article_card, {
      key: article.id,
      article: article,
      reverse: index % 2 == 1
    }, null, 8, ["article", "reverse"]);
  }), 128)), $setup.articleCount > 0 ? (_openBlock(), _createBlock(_component_el_pagination, {
    key: 0,
    background: "",
    layout: "prev, pager, next",
    total: $setup.articleCount,
    "page-size": $setup.pageSize,
    id: "pagination",
    onCurrentChange: $setup.onCurrentPageChanged
  }, null, 8, ["total", "page-size", "onCurrentChange"])) : _createCommentVNode("", true)])]), _createVNode(_component_kila_kila_footer), _createVNode(_component_kila_kila_back_to_top)]);
}