import "core-js/modules/es.array.push.js";
import { computed, reactive, ref } from "vue";
import { getPostArticleList } from "../../api/article";
import { defaultThumbnail } from "../../utils/thumbnail";
import { mapState } from "../../store/map";
export default {
  name: "CategoryDetails",
  setup(props) {
    let pageSize = 10;
    let postArticles = reactive([]);
    let articleCount = ref(0);
    let {
      categoryCounts
    } = mapState("categoryAbout");
    let categoryName = computed(() => {
      let map = Object.fromEntries(categoryCounts.value.map(i => [i.id, i.name]));
      return map[props.id];
    });
    onCurrentPageChanged(1);
    function onCurrentPageChanged(pageNum) {
      getPostArticleList(pageNum, pageSize, props.id).then(data => {
        articleCount.value = parseInt(data.total);
        data.rows.forEach(article => {
          article.createTime = article.createTime.split(" ")[0];
          article.thumbnail = article.thumbnail || defaultThumbnail;
        });
        postArticles.push(...data.rows);
      });
    }
    window.scrollTo({
      top: 0
    });
    return {
      categoryName,
      postArticles,
      articleCount,
      pageSize,
      onCurrentPageChanged
    };
  },
  props: ["id", "name"]
};