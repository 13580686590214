import "core-js/modules/es.array.at.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.set.difference.v2.js";
import "core-js/modules/es.set.intersection.v2.js";
import "core-js/modules/es.set.is-disjoint-from.v2.js";
import "core-js/modules/es.set.is-subset-of.v2.js";
import "core-js/modules/es.set.is-superset-of.v2.js";
import "core-js/modules/es.set.symmetric-difference.v2.js";
import "core-js/modules/es.set.union.v2.js";
import { reactive, ref } from "vue";
export default {
  name: "KilaKilaCatalog",
  setup(props) {
    let titles = reactive(getTitles());
    let currentTitle = reactive({});
    let progress = ref(0);

    // 获取目录的标题
    function getTitles() {
      let titles = [];
      let levels = ["h1", "h2", "h3"];
      let articleElement = document.querySelector(props.container);
      if (!articleElement) {
        return titles;
      }
      let elements = Array.from(articleElement.querySelectorAll("*"));

      // 调整标签等级
      let tagNames = new Set(elements.map(el => el.tagName.toLowerCase()));
      for (let i = levels.length - 1; i >= 0; i--) {
        if (!tagNames.has(levels[i])) {
          levels.splice(i, 1);
        }
      }
      let serialNumbers = levels.map(() => 0);
      for (let i = 0; i < elements.length; i++) {
        const element = elements[i];
        let tagName = element.tagName.toLowerCase();
        let level = levels.indexOf(tagName);
        if (level == -1) continue;
        let id = tagName + "-" + element.innerText + "-" + i;
        let node = {
          id,
          level,
          parent: null,
          children: [],
          rawName: element.innerText,
          scrollTop: element.offsetTop
        };
        if (titles.length > 0) {
          let lastNode = titles.at(-1);

          // 遇到子标题
          if (lastNode.level < node.level) {
            node.parent = lastNode;
            lastNode.children.push(node);
          }
          // 遇到上一级标题
          else if (lastNode.level > node.level) {
            serialNumbers.fill(0, level + 1);
            let parent = lastNode.parent;
            while (parent) {
              if (parent.level < node.level) {
                parent.children.push(node);
                node.parent = parent;
                break;
              }
              parent = parent.parent;
            }
          }
          // 遇到平级
          else if (lastNode.parent) {
            node.parent = lastNode.parent;
            lastNode.parent.children.push(node);
          }
        }
        serialNumbers[level] += 1;
        let serialNumber = serialNumbers.slice(0, level + 1).join(".");
        node.isVisible = node.parent == null;
        node.name = serialNumber + ". " + element.innerText;
        titles.push(node);
      }
      return titles;
    }

    // 监听滚动事件并更新样式
    window.addEventListener("scroll", function () {
      progress.value = parseInt(window.scrollY / document.documentElement.scrollHeight * 100) + "%";
      let visibleTitles = [];
      for (let i = titles.length - 1; i >= 0; i--) {
        const title = titles[i];
        if (title.scrollTop <= window.scrollY) {
          if (currentTitle.id === title.id) return;
          Object.assign(currentTitle, title);

          // 展开节点
          setChildrenVisible(title, true);
          visibleTitles.push(title);

          // 展开父节点
          let parent = title.parent;
          while (parent) {
            setChildrenVisible(parent, true);
            visibleTitles.push(parent);
            parent = parent.parent;
          }

          // 折叠其余节点
          for (const t of titles) {
            if (!visibleTitles.includes(t)) {
              setChildrenVisible(t, false);
            }
          }
          return;
        }
      }
    });

    // 设置子节点的可见性
    function setChildrenVisible(title, isVisible) {
      for (const child of title.children) {
        child.isVisible = isVisible;
      }
    }

    // 滚动到指定的位置
    function scrollToView(scrollTop) {
      window.scrollTo({
        top: scrollTop,
        behavior: "smooth"
      });
    }
    return {
      titles,
      currentTitle,
      progress,
      scrollToView
    };
  },
  props: {
    container: {
      type: String,
      default: ".post-body .article-content"
    }
  }
};