import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "card-body"
};
const _hoisted_2 = {
  class: "title"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_card = _resolveComponent("el-card");
  return _openBlock(), _createBlock(_component_el_card, {
    class: "box-card"
  }, {
    header: _withCtx(() => _cache[0] || (_cache[0] = [_createElementVNode("div", {
      class: "card-header"
    }, [_createElementVNode("h2", null, "HadLuo-个人博客")], -1)])),
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("p", _hoisted_2, _toDisplayString($props.title), 1), _renderSlot(_ctx.$slots, "default", {}, () => [_cache[1] || (_cache[1] = _createTextVNode("表单内容"))], true)])]),
    _: 3
  });
}