import "core-js/modules/es.array.push.js";
import { reactive, computed, ref } from "vue";
import { fallbackAvatar } from "../utils/avatar";
import markdownIt from "../utils/markdown-it";
import { getUserInfo } from "../utils/storage";
export default {
  name: "KilaKilaCommentItem",
  props: {
    comment: {
      type: Object,
      require: true
    },
    floorNumber: {
      type: Number,
      require: true
    }
  },
  emits: ["reply", "update", "delete"],
  setup(props, context) {
    let commentClass = reactive(["comment-item"]);
    if (props.comment.isAdmin) {
      commentClass.push("comment-item-admin");
    }
    let content = computed(() => markdownIt.render(props.comment.content));
    let moreActionsMenu = ref();
    let userInfo = getUserInfo();
    let canModify = userInfo ? ref(userInfo.isAdmin || props.comment.createBy == userInfo.id) : ref(false);
    function scrollToAnchor(event) {
      event.target.scrollIntoView({
        behavior: "smooth"
      });
    }
    function showMoreActionsMenu() {
      moreActionsMenu.value.handleOpen();
    }
    function handleMenuCommand(command) {
      context.emit(command, props.comment);
    }
    return {
      commentClass,
      content,
      moreActionsMenu,
      canModify,
      scrollToAnchor,
      showMoreActionsMenu,
      handleMenuCommand
    };
  }
};