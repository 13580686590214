import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "card"
};
const _hoisted_2 = {
  class: "card-header"
};
const _hoisted_3 = {
  class: "card-body"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("span", {
    style: _normalizeStyle($setup.headerIconStyle)
  }, [_createVNode(_component_font_awesome_icon, {
    icon: $props.icon,
    class: "header-icon"
  }, null, 8, ["icon"])], 4), _createElementVNode("span", null, _toDisplayString($props.title), 1)]), _createElementVNode("div", _hoisted_3, [_renderSlot(_ctx.$slots, "default", {}, () => [_cache[0] || (_cache[0] = _createTextVNode("卡片体"))], true)])]);
}