import { mapState } from "../store/map";
import { computed } from "vue";
export default {
  name: "KilaKilaAdminCard",
  setup() {
    let {
      adminInfo,
      articleCountInfo
    } = mapState("adminAbout");
    function gotoGithub2() {
      location.href = adminInfo.value.githubUrl;
    }
    return {
      adminInfo,
      articleCountInfo,
      gotoGithub2,
      computed
    };
  }
};