import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  id: "home"
};
const _hoisted_2 = {
  class: "container"
};
const _hoisted_3 = {
  class: "side-content"
};
const _hoisted_4 = {
  class: "post-article-list"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_kila_kila_header = _resolveComponent("kila-kila-header");
  const _component_kila_kila_cover = _resolveComponent("kila-kila-cover");
  const _component_kila_kila_admin_card = _resolveComponent("kila-kila-admin-card");
  const _component_kila_kila_hot_article_card = _resolveComponent("kila-kila-hot-article-card");
  const _component_kila_kila_category_card = _resolveComponent("kila-kila-category-card");
  const _component_kila_kila_tag_card = _resolveComponent("kila-kila-tag-card");
  const _component_kila_kila_archive_card = _resolveComponent("kila-kila-archive-card");
  const _component_kila_kila_post_article_card = _resolveComponent("kila-kila-post-article-card");
  const _component_el_pagination = _resolveComponent("el-pagination");
  const _component_kila_kila_footer = _resolveComponent("kila-kila-footer");
  const _component_kila_kila_back_to_top = _resolveComponent("kila-kila-back-to-top");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_kila_kila_header), _createVNode(_component_kila_kila_cover, {
    imgUrl: "assets/image/home-cover.jpg",
    title: "欢迎来到HadLuo的个人博客",
    content: "从这里你能得到你想要的一切"
  }), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_kila_kila_admin_card), _createVNode(_component_kila_kila_hot_article_card), _createVNode(_component_kila_kila_category_card), _createVNode(_component_kila_kila_tag_card), _createVNode(_component_kila_kila_archive_card)]), _createElementVNode("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.postArticles, (article, index) => {
    return _openBlock(), _createBlock(_component_kila_kila_post_article_card, {
      key: article.id,
      article: article,
      reverse: index % 2 == 1
    }, null, 8, ["article", "reverse"]);
  }), 128)), $setup.articleCount > 0 ? (_openBlock(), _createBlock(_component_el_pagination, {
    key: 0,
    background: "",
    layout: "prev, pager, next",
    total: $setup.articleCount,
    "page-size": $setup.pageSize,
    id: "pagination",
    onCurrentChange: $setup.onCurrentPageChanged
  }, null, 8, ["total", "page-size", "onCurrentChange"])) : _createCommentVNode("", true)])]), _createVNode(_component_kila_kila_footer, {
    adminName: _ctx.$store.state.adminAbout.adminInfo.nickName
  }, null, 8, ["adminName"]), _createVNode(_component_kila_kila_back_to_top)]);
}