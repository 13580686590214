import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle } from "vue";
const _hoisted_1 = {
  id: "article-details"
};
const _hoisted_2 = {
  class: "article-info"
};
const _hoisted_3 = {
  class: "article-title"
};
const _hoisted_4 = {
  class: "article-meta-data-wrap"
};
const _hoisted_5 = {
  class: "article-meta-data"
};
const _hoisted_6 = {
  class: "article-meta-data"
};
const _hoisted_7 = {
  class: "article-meta-data"
};
const _hoisted_8 = {
  class: "container"
};
const _hoisted_9 = {
  class: "sticky-layout"
};
const _hoisted_10 = {
  class: "post-body"
};
const _hoisted_11 = ["innerHTML"];
const _hoisted_12 = {
  class: "article-signature"
};
const _hoisted_13 = ["src"];
const _hoisted_14 = {
  class: "copyright"
};
const _hoisted_15 = {
  class: "copyright-item"
};
const _hoisted_16 = {
  class: "copyright-content"
};
const _hoisted_17 = {
  class: "copyright-item"
};
const _hoisted_18 = {
  class: "copyright-content"
};
const _hoisted_19 = ["href"];
const _hoisted_20 = {
  key: 0,
  class: "article-tags"
};
const _hoisted_21 = {
  class: "previous-next-article"
};
const _hoisted_22 = ["src"];
const _hoisted_23 = {
  class: "previous-article-info"
};
const _hoisted_24 = {
  class: "title"
};
const _hoisted_25 = ["src"];
const _hoisted_26 = {
  class: "next-article-info"
};
const _hoisted_27 = {
  class: "title"
};
const _hoisted_28 = {
  id: "article-desc"
};
const _hoisted_29 = {
  key: 1,
  id: "comment-area"
};
const _hoisted_30 = {
  class: "comment-area-title"
};
const _hoisted_31 = {
  id: "comment-items"
};
const _hoisted_32 = {
  id: "comment-form"
};
const _hoisted_33 = {
  id: "comment-editor"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_kila_kila_header = _resolveComponent("kila-kila-header");
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon");
  const _component_kila_kila_wife_cover = _resolveComponent("kila-kila-wife-cover");
  const _component_kila_kila_admin_card = _resolveComponent("kila-kila-admin-card");
  const _component_kila_kila_catalog_card = _resolveComponent("kila-kila-catalog-card");
  const _component_kila_kila_hot_article_card = _resolveComponent("kila-kila-hot-article-card");
  const _component_kila_kila_side_bar = _resolveComponent("kila-kila-side-bar");
  const _component_router_link = _resolveComponent("router-link");
  const _component_kila_kila_comment_item = _resolveComponent("kila-kila-comment-item");
  const _component_el_pagination = _resolveComponent("el-pagination");
  const _component_mavon_editor = _resolveComponent("mavon-editor");
  const _component_kila_kila_back_to_top = _resolveComponent("kila-kila-back-to-top");
  const _component_kila_kila_light_box = _resolveComponent("kila-kila-light-box");
  const _component_kila_kila_footer = _resolveComponent("kila-kila-footer");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_kila_kila_header), _createVNode(_component_kila_kila_wife_cover, null, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createElementVNode("h1", _hoisted_3, [_createTextVNode(_toDisplayString($setup.articleDetails.title) + " ", 1), $setup.isAdmin ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
      key: 0,
      icon: ['fas', 'pen'],
      class: "edit-icon",
      title: "编辑",
      onClick: $setup.editArticle
    }, null, 8, ["onClick"])) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_4, [_createElementVNode("span", _hoisted_5, [_createVNode(_component_font_awesome_icon, {
      icon: ['fas', 'calendar-days']
    }), _createTextVNode(" 发表于 " + _toDisplayString($setup.articleDetails.createTime), 1)]), _cache[4] || (_cache[4] = _createElementVNode("span", {
      class: "article-meta-data-divider"
    }, "|", -1)), _createElementVNode("span", _hoisted_6, [_createVNode(_component_font_awesome_icon, {
      icon: ['fas', 'box-archive']
    }), _createTextVNode(" 分类 " + _toDisplayString($setup.articleDetails.categoryName), 1)]), _cache[5] || (_cache[5] = _createElementVNode("span", {
      class: "article-meta-data-divider"
    }, "|", -1)), _createElementVNode("span", _hoisted_7, [_createVNode(_component_font_awesome_icon, {
      icon: ['fas', 'eye']
    }), _createTextVNode(" 阅读量 " + _toDisplayString($setup.articleDetails.viewCount), 1)])])])]),
    _: 1
  }), _createElementVNode("div", _hoisted_8, [_createVNode(_component_kila_kila_side_bar, null, {
    default: _withCtx(() => [_createVNode(_component_kila_kila_admin_card), _createElementVNode("div", _hoisted_9, [$setup.articleLoaded ? (_openBlock(), _createBlock(_component_kila_kila_catalog_card, {
      key: 0
    })) : _createCommentVNode("", true), _createVNode(_component_kila_kila_hot_article_card)])]),
    _: 1
  }), _createElementVNode("div", _hoisted_10, [_createElementVNode("div", {
    class: "article-content",
    innerHTML: $setup.articleDetails.content
  }, null, 8, _hoisted_11), _createElementVNode("div", _hoisted_12, [_createElementVNode("img", {
    src: $setup.adminInfo.avatar,
    alt: "头像"
  }, null, 8, _hoisted_13), _createElementVNode("div", _hoisted_14, [_createElementVNode("div", _hoisted_15, [_cache[6] || (_cache[6] = _createElementVNode("span", {
    class: "copyright-title"
  }, "文章作者：", -1)), _createElementVNode("span", _hoisted_16, [_createVNode(_component_router_link, {
    to: "/"
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString($setup.adminInfo.nickName), 1)]),
    _: 1
  })])]), _createElementVNode("div", _hoisted_17, [_cache[7] || (_cache[7] = _createElementVNode("span", {
    class: "copyright-title"
  }, "文章链接：", -1)), _createElementVNode("span", _hoisted_18, [_createElementVNode("a", {
    href: $setup.articleUrl
  }, _toDisplayString($setup.articleUrl), 9, _hoisted_19)])]), _cache[8] || (_cache[8] = _createElementVNode("div", {
    class: "copyright-item"
  }, [_createElementVNode("span", {
    class: "copyright-title"
  }, "版权声明："), _createElementVNode("span", {
    class: "copyright-content"
  }, " 没什么版权，情任意转载博客 ")], -1))])]), $setup.articleDetails.tags ? (_openBlock(), _createElementBlock("div", _hoisted_20, [_createElementVNode("span", null, [_createVNode(_component_font_awesome_icon, {
    icon: ['fas', 'tags']
  }), _cache[9] || (_cache[9] = _createTextVNode(" 标签： "))]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.articleDetails.tags, tag => {
    return _openBlock(), _createBlock(_component_router_link, {
      to: '/tag/' + tag.id,
      key: tag.id,
      class: "tag-link"
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(tag.name), 1)]),
      _: 2
    }, 1032, ["to"]);
  }), 128))])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_21, [$setup.previousArticle.id ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: "previous-article",
    style: _normalizeStyle({
      width: $setup.nextArticle.id ? '50%' : '100%'
    })
  }, [_createVNode(_component_router_link, {
    to: `/article/${$setup.previousArticle.id}`
  }, {
    default: _withCtx(() => [_createElementVNode("img", {
      src: $setup.previousArticle.thumbnail,
      alt: "缩略图",
      onErrorOnce: _cache[0] || (_cache[0] = (...args) => $setup.useDefaultThumbnail && $setup.useDefaultThumbnail(...args))
    }, null, 40, _hoisted_22), _createElementVNode("div", _hoisted_23, [_cache[10] || (_cache[10] = _createElementVNode("div", {
      class: "label"
    }, "« 上一篇", -1)), _createElementVNode("div", _hoisted_24, _toDisplayString($setup.previousArticle.title), 1)])]),
    _: 1
  }, 8, ["to"])], 4)) : _createCommentVNode("", true), $setup.nextArticle.id ? (_openBlock(), _createElementBlock("div", {
    key: 1,
    class: "next-article",
    style: _normalizeStyle({
      width: $setup.previousArticle.id ? '50%' : '100%'
    })
  }, [_createVNode(_component_router_link, {
    to: `/article/${$setup.nextArticle.id}`
  }, {
    default: _withCtx(() => [_createElementVNode("img", {
      src: $setup.nextArticle.thumbnail,
      alt: "缩略图",
      onErrorOnce: _cache[1] || (_cache[1] = (...args) => $setup.useDefaultThumbnail && $setup.useDefaultThumbnail(...args))
    }, null, 40, _hoisted_25), _createElementVNode("div", _hoisted_26, [_cache[11] || (_cache[11] = _createElementVNode("div", {
      class: "label"
    }, "下一篇 »", -1)), _createElementVNode("div", _hoisted_27, _toDisplayString($setup.nextArticle.title), 1)])]),
    _: 1
  }, 8, ["to"])], 4)) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_28, [_createTextVNode(" posted @ " + _toDisplayString($setup.articleDetails.createTime.slice(0, -3)) + " ", 1), _createElementVNode("span", null, _toDisplayString($setup.adminInfo.nickName), 1), _createElementVNode("span", null, "阅读(" + _toDisplayString($setup.articleDetails.viewCount) + ")", 1), _createElementVNode("span", null, "评论(" + _toDisplayString($setup.commentCount) + ")", 1), $setup.isAdmin ? (_openBlock(), _createBlock(_component_router_link, {
    key: 0,
    to: `/article/${$setup.articleDetails.id}/edit`
  }, {
    default: _withCtx(() => _cache[12] || (_cache[12] = [_createTextVNode("编辑")])),
    _: 1
  }, 8, ["to"])) : _createCommentVNode("", true)]), $setup.comments.length > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_29, [_createElementVNode("div", _hoisted_30, [_createVNode(_component_font_awesome_icon, {
    icon: ['fas', 'comments'],
    class: "comment-icon"
  }), _cache[13] || (_cache[13] = _createTextVNode("评论列表 "))]), _createElementVNode("div", _hoisted_31, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.comments, (comment, index) => {
    return _openBlock(), _createBlock(_component_kila_kila_comment_item, {
      key: comment.id,
      comment: comment,
      floorNumber: ($setup.currentCommentPageNum - 1) * $setup.commentPageSize + index + 1,
      onReply: $setup.onReplyComment,
      onUpdate: $setup.onUpdateComment,
      onDelete: $setup.onDeleteComment
    }, null, 8, ["comment", "floorNumber", "onReply", "onUpdate", "onDelete"]);
  }), 128))]), $setup.commentCount > 0 ? (_openBlock(), _createBlock(_component_el_pagination, {
    key: 0,
    background: "",
    layout: "prev, pager, next",
    total: $setup.commentCount,
    "page-size": $setup.commentPageSize,
    id: "comment-pagination",
    onCurrentChange: $setup.onCurrentCommentPageChanged
  }, null, 8, ["total", "page-size", "onCurrentChange"])) : _createCommentVNode("", true)])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_32, [_cache[14] || (_cache[14] = _createElementVNode("div", {
    id: "comment-form-title"
  }, "✏️ 发表评论", -1)), _createElementVNode("div", _hoisted_33, [_createVNode(_component_mavon_editor, {
    modelValue: $setup.commentContent,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => $setup.commentContent = $event),
    id: "mavon",
    codeStyle: "atom-one-dark",
    autofocus: false,
    boxShadow: false,
    onImgAdd: $setup.onImageAdded,
    ref: "mavonRef",
    placeholder: "发表一条伟大的评论吧~",
    defaultOpen: "edit",
    toolbars: $setup.mavonToolbarOption,
    subfield: false
  }, null, 8, ["modelValue", "onImgAdd", "toolbars"]), _createElementVNode("button", {
    id: "comment-submit-btn",
    onClick: _cache[3] || (_cache[3] = (...args) => $setup.submitComment && $setup.submitComment(...args))
  }, _toDisplayString($setup.isInEditMode ? "修改评论" : "提交评论"), 1)])])])]), _createVNode(_component_kila_kila_back_to_top), $setup.articleLoaded ? (_openBlock(), _createBlock(_component_kila_kila_light_box, {
    key: 0,
    ref: "lightBoxRef"
  }, null, 512)) : _createCommentVNode("", true), _createVNode(_component_kila_kila_footer)]);
}