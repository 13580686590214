import store from "./store";
import bus from "./utils/bus";
export default {
  name: "App",
  setup() {
    store.dispatch("adminAbout/getAdminInfo");
    updateArticleInfo();
    bus.on("articlePosted", updateArticleInfo);
    function updateArticleInfo() {
      store.dispatch("adminAbout/getArticleCount");
      store.dispatch("categoryAbout/getCategoryCounts");
      store.dispatch("tagAbout/getTagCounts");
    }
  }
};