import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  class: "cloud-card"
};
const _hoisted_2 = {
  class: "clouds"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_link = _resolveComponent("router-link");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.clouds, cloud => {
    return _openBlock(), _createBlock(_component_router_link, {
      class: "cloud-item",
      key: cloud.id,
      to: `${$props.baseUrl}/${cloud.id}`,
      style: _normalizeStyle(cloud.style)
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(cloud.name), 1)]),
      _: 2
    }, 1032, ["to", "style"]);
  }), 128))])]);
}