import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "register"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_User = _resolveComponent("User");
  const _component_el_icon = _resolveComponent("el-icon");
  const _component_el_input = _resolveComponent("el-input");
  const _component_el_form_item = _resolveComponent("el-form-item");
  const _component_Postcard = _resolveComponent("Postcard");
  const _component_Promotion = _resolveComponent("Promotion");
  const _component_Lock = _resolveComponent("Lock");
  const _component_kila_kila_button = _resolveComponent("kila-kila-button");
  const _component_el_form = _resolveComponent("el-form");
  const _component_kila_kila_form_card = _resolveComponent("kila-kila-form-card");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_kila_kila_form_card, {
    title: "你来啦"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_form, {
      ref: "ruleFormRef",
      model: $setup.ruleForm,
      rules: $setup.rules,
      "label-width": "0px",
      class: "demo-ruleForm"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_form_item, {
        prop: "userName"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_input, {
          modelValue: $setup.ruleForm.userName,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $setup.ruleForm.userName = $event),
          class: "w-50 m-3",
          size: "large",
          placeholder: "用户名"
        }, {
          prefix: _withCtx(() => [_createVNode(_component_el_icon, {
            class: "el-input__icon"
          }, {
            default: _withCtx(() => [_createVNode(_component_User)]),
            _: 1
          })]),
          _: 1
        }, 8, ["modelValue"])]),
        _: 1
      }), _createVNode(_component_el_form_item, {
        prop: "nickName"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_input, {
          modelValue: $setup.ruleForm.nickName,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $setup.ruleForm.nickName = $event),
          class: "w-50 m-3",
          size: "large",
          placeholder: "昵称"
        }, {
          prefix: _withCtx(() => [_createVNode(_component_el_icon, {
            class: "el-input__icon"
          }, {
            default: _withCtx(() => [_createVNode(_component_Postcard)]),
            _: 1
          })]),
          _: 1
        }, 8, ["modelValue"])]),
        _: 1
      }), _createVNode(_component_el_form_item, {
        prop: "email"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_input, {
          modelValue: $setup.ruleForm.email,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => $setup.ruleForm.email = $event),
          class: "w-50 m-3",
          type: "email",
          size: "large",
          placeholder: "邮箱"
        }, {
          prefix: _withCtx(() => [_createVNode(_component_el_icon, {
            class: "el-input__icon"
          }, {
            default: _withCtx(() => [_createVNode(_component_Promotion)]),
            _: 1
          })]),
          _: 1
        }, 8, ["modelValue"])]),
        _: 1
      }), _createVNode(_component_el_form_item, {
        prop: "password"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_input, {
          modelValue: $setup.ruleForm.password,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => $setup.ruleForm.password = $event),
          class: "w-50 m-2",
          size: "large",
          type: "password",
          placeholder: "密码"
        }, {
          prefix: _withCtx(() => [_createVNode(_component_el_icon, {
            class: "el-input__icon"
          }, {
            default: _withCtx(() => [_createVNode(_component_Lock)]),
            _: 1
          })]),
          _: 1
        }, 8, ["modelValue"])]),
        _: 1
      }), _createVNode(_component_el_form_item, {
        prop: "ensurePassword"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_input, {
          modelValue: $setup.ruleForm.ensurePassword,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => $setup.ruleForm.ensurePassword = $event),
          class: "w-50 m-2",
          size: "large",
          type: "password",
          placeholder: "确认密码"
        }, {
          prefix: _withCtx(() => [_createVNode(_component_el_icon, {
            class: "el-input__icon"
          }, {
            default: _withCtx(() => [_createVNode(_component_Lock)]),
            _: 1
          })]),
          _: 1
        }, 8, ["modelValue"])]),
        _: 1
      }), _createVNode(_component_el_form_item, null, {
        default: _withCtx(() => [_createVNode(_component_kila_kila_button, {
          onClick: _cache[5] || (_cache[5] = $event => $setup.submitForm($setup.ruleFormRef))
        }, {
          default: _withCtx(() => _cache[6] || (_cache[6] = [_createTextVNode("注册")])),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    }, 8, ["model", "rules"])]),
    _: 1
  })]);
}