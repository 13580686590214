import { computed } from "vue";
import { randomColorWordCloud } from "../utils/word-cloud";
export default {
  name: "KilaKilaWordCloudCard",
  emits: ["wordClicked"],
  props: {
    words: {
      type: Array,
      require: true
    },
    baseUrl: {
      type: String,
      require: true
    }
  },
  setup(props) {
    let clouds = computed(() => randomColorWordCloud(props.words));
    return {
      clouds
    };
  }
};