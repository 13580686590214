import { ref } from "vue";
export default {
  name: "KilaKilaBackToTop",
  setup() {
    let buttonClass = ref("hidden");
    function scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }
    window.addEventListener("scroll", function () {
      if (window.scrollY > 300) {
        buttonClass.value = "";
      } else {
        buttonClass.value = "hidden";
      }
    });
    return {
      buttonClass,
      scrollToTop
    };
  }
};