import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  id: "side-bar"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_kila_kila_admin_card = _resolveComponent("kila-kila-admin-card");
  const _component_kila_kila_hot_article_card = _resolveComponent("kila-kila-hot-article-card");
  const _component_kila_kila_category_card = _resolveComponent("kila-kila-category-card");
  const _component_kila_kila_tag_card = _resolveComponent("kila-kila-tag-card");
  const _component_kila_kila_archive_card = _resolveComponent("kila-kila-archive-card");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_renderSlot(_ctx.$slots, "default", {}, () => [_createVNode(_component_kila_kila_admin_card), _createVNode(_component_kila_kila_hot_article_card), _createVNode(_component_kila_kila_category_card), _createVNode(_component_kila_kila_tag_card), _createVNode(_component_kila_kila_archive_card)], true)]);
}