import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import { reactive, ref, toRefs } from "@vue/reactivity";
import { getUserInfo, setUserInfo } from "../utils/storage";
import { logout, updateUserInfo } from "../api/user";
import router from "../router";
import { uploadImage } from "../api/image";
import { ElMessage } from "element-plus/lib/components";
import store from "../store";
export default {
  name: "Settings",
  setup() {
    let userInfo = reactive(getUserInfo());
    let isInEditMode = ref(false);
    let ruleFormRef = ref();
    let progress = ref(0);
    let isProgressVisible = ref(false);
    let ruleForm = reactive({
      nickName: userInfo.nickName,
      sex: userInfo.sex == "0" ? "男" : "女",
      email: userInfo.email,
      signature: userInfo.signature,
      phonenumber: userInfo.phonenumber,
      avatar: userInfo.avatar
    });
    let rules = reactive({
      nickName: [{
        required: true,
        message: "昵称不能为空",
        trigger: "blur"
      }],
      email: [{
        type: "email",
        required: true,
        message: "邮箱格式错误",
        trigger: "blur"
      }],
      phonenumber: [{
        required: false,
        message: "手机号码格式错误",
        trigger: "blur",
        pattern: /(^((\+86)|(86))?(1[3-9])\d{9}$)|(^(0\d{2,3})-?(\d{7,8})$)/
      }]
    });
    function handleLogout() {
      logout().then(() => {
        router.replace("/login");
      });
    }
    function openFileDialog() {
      document.getElementById("file-input").click();
    }
    function uploadAvatar() {
      let fileInput = document.getElementById("file-input");
      if (fileInput.files.length == 0) {
        return;
      }
      isProgressVisible.value = true;
      let file = fileInput.files[0];
      document.getElementById("avatar").src = URL.createObjectURL(file);
      uploadImage(file, progress).then(url => {
        ruleForm.avatar = url;
        isProgressVisible.value = false;
      }, () => {
        ElMessage.error("哎呀，头像上传出错啦~");
        isProgressVisible.value = false;
        document.getElementById("avatar").src = ruleForm.avatar;
      });
    }
    function saveEditInfo(form) {
      if (!form) return;
      form.validate(valid => {
        if (!valid) {
          ElMessage.error("前辈填写的个人信息有点小问题哦~");
          return;
        }
        Object.assign(userInfo, ruleForm);
        userInfo.sex = ruleForm.sex == "男" ? "0" : "1";
        updateUserInfo(userInfo).then(data => {
          setUserInfo(userInfo);
          isInEditMode.value = false;
          ElMessage.success("更新前辈个人信息成功啦");
          if (store.state.adminAbout.isAdmin) {
            store.commit("adminAbout/updateAdminInfo", userInfo);
          }
        });
      });
    }
    function cancelEditInfo() {
      Object.assign(ruleForm, userInfo);
      ruleForm.sex = userInfo.sex == "0" ? "男" : "女";
      document.getElementById("avatar").src = ruleForm.avatar;
      ruleFormRef.value.clearValidate();
      isInEditMode.value = false;
    }
    return {
      ...toRefs(userInfo),
      handleLogout,
      saveEditInfo,
      cancelEditInfo,
      openFileDialog,
      uploadAvatar,
      isProgressVisible,
      isInEditMode,
      ruleFormRef,
      progress,
      ruleForm,
      rules
    };
  }
};