import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import { ref, computed } from "vue";
import { uploadImage } from "../api/image";
import { Plus } from "@element-plus/icons-vue";
import VueEasyLightbox from "vue-easy-lightbox";
import { ElMessage } from 'element-plus/lib/components';
export default {
  name: "KilaKilaUploader",
  emits: ["uploaded", "aboutToUpload", "removed"],
  components: {
    Plus,
    VueEasyLightbox
  },
  setup(props, context) {
    let progress = ref(0);
    let isLightBoxVisible = ref(false);
    let isProgressVisible = ref(false);
    let isSuccessLabelVisible = ref(false);
    let imageUrl = ref("");
    let localImageUrl = ref("");
    let index = ref(0);
    let isThumbnailVisible = computed(() => localImageUrl.value.length > 0);
    function openFileDialog() {
      document.getElementById("file-input").click();
    }
    function onImageAdded() {
      let fileInput = document.getElementById("file-input");
      if (fileInput.files.length == 0) {
        return;
      }
      context.emit("aboutToUpload");
      let file = fileInput.files[0];
      setImageUrl(URL.createObjectURL(file));
      upload(file);
    }
    function setImageUrl(url) {
      let thumbnailEl = document.getElementById("thumbnail");
      thumbnailEl.src = localImageUrl.value = url;
    }
    function handleThumbnailRemove(file) {
      imageUrl.value = "";
      localImageUrl.value = "";
      context.emit("removed", file);
    }
    function handleThumbnailPreview() {
      isLightBoxVisible.value = true;
    }
    function handleLightboxHide() {
      isLightBoxVisible.value = false;
    }
    function upload(file) {
      progress.value = 0;
      isProgressVisible.value = true;
      isSuccessLabelVisible.value = false;
      uploadImage(file, progress).then(url => {
        progress.value = 100;
        imageUrl.value = url;
        document.getElementById("thumbnail").src = url;
        context.emit("uploaded", url);
        setTimeout(() => {
          isProgressVisible.value = false;
          isSuccessLabelVisible.value = true;
        }, 200);
      }, () => {
        isProgressVisible.value = false;
        localImageUrl.value = "";
        context.emit("uploaded", "");
        ElMessage.error("哎呀，图片上传出错啦~");
      });
    }
    return {
      progress,
      imageUrl,
      localImageUrl,
      index,
      isLightBoxVisible,
      isThumbnailVisible,
      isProgressVisible,
      isSuccessLabelVisible,
      handleThumbnailRemove,
      handleThumbnailPreview,
      handleLightboxHide,
      openFileDialog,
      onImageAdded,
      setImageUrl
    };
  }
};