import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  class: "tag-clouds"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_link = _resolveComponent("router-link");
  const _component_kila_kila_card = _resolveComponent("kila-kila-card");
  return _openBlock(), _createBlock(_component_kila_kila_card, {
    icon: ['fas', 'tags'],
    iconColor: "#db669f",
    title: "标签"
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.tagClouds, tag => {
      return _openBlock(), _createBlock(_component_router_link, {
        class: "tag-item",
        key: tag.id,
        to: `/tag/${tag.id}`,
        style: _normalizeStyle(tag.style)
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(tag.name), 1)]),
        _: 2
      }, 1032, ["to", "style"]);
    }), 128))])]),
    _: 1
  });
}