import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "admin-card"
};
const _hoisted_2 = {
  class: "admin-description"
};
const _hoisted_3 = {
  class: "article-info-container"
};
const _hoisted_4 = {
  style: {
    "color": "#800080"
  },
  class: "article-info"
};
const _hoisted_5 = {
  style: {
    "color": "#800080"
  },
  class: "article-info"
};
const _hoisted_6 = {
  style: {
    "color": "#800080"
  },
  class: "article-info"
};
const _hoisted_7 = {
  class: "social-icon-container"
};
const _hoisted_8 = ["href"];
const _hoisted_9 = ["href"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_avatar = _resolveComponent("el-avatar");
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon");
  const _component_el_button = _resolveComponent("el-button");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_avatar, {
    size: 50,
    src: $setup.adminInfo.avatar,
    class: "avatar"
  }, null, 8, ["src"]), _createElementVNode("div", _hoisted_2, [_createElementVNode("h3", null, _toDisplayString($setup.adminInfo.nickName), 1), _createElementVNode("p", null, _toDisplayString($setup.adminInfo.signature), 1)]), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_cache[0] || (_cache[0] = _createElementVNode("p", null, "文章", -1)), _createElementVNode("p", null, _toDisplayString($setup.articleCountInfo.article), 1)]), _createElementVNode("div", _hoisted_5, [_cache[1] || (_cache[1] = _createElementVNode("p", null, "分类", -1)), _createElementVNode("p", null, _toDisplayString($setup.articleCountInfo.category), 1)]), _createElementVNode("div", _hoisted_6, [_cache[2] || (_cache[2] = _createElementVNode("p", null, "标签", -1)), _createElementVNode("p", null, _toDisplayString($setup.articleCountInfo.tag), 1)])]), _createVNode(_component_el_button, {
    type: "primary",
    class: "follow-button"
  }, {
    default: _withCtx(() => [_createVNode(_component_font_awesome_icon, {
      icon: ['fab', 'qq'],
      class: "fa-qq"
    }), _cache[3] || (_cache[3] = _createTextVNode(" 联系我"))]),
    _: 1
  }), _createElementVNode("div", _hoisted_7, [_createVNode(_component_font_awesome_icon, {
    icon: ['fab', 'qq'],
    class: "social-icon",
    id: "qq"
  }), _createElementVNode("a", {
    href: 'mailto:' + $setup.adminInfo.email
  }, [_createVNode(_component_font_awesome_icon, {
    icon: ['fas', 'envelope'],
    class: "social-icon",
    id: "email"
  })], 8, _hoisted_8), _createVNode(_component_font_awesome_icon, {
    icon: ['fab', 'qq'],
    class: "social-icon",
    id: "qq"
  }), _createElementVNode("a", {
    href: 'mailto:' + $setup.adminInfo.email
  }, [_createVNode(_component_font_awesome_icon, {
    icon: ['fas', 'envelope'],
    class: "social-icon",
    id: "email"
  })], 8, _hoisted_9)])]);
}