import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_vue_easy_lightbox = _resolveComponent("vue-easy-lightbox");
  return _openBlock(), _createBlock(_component_vue_easy_lightbox, {
    moveDisabled: "",
    visible: $setup.isVisible,
    imgs: $setup.images,
    index: $setup.index,
    onHide: $setup.handleHide
  }, null, 8, ["visible", "imgs", "index", "onHide"]);
}