import { reactive, ref } from "vue";
import { getPostArticleList } from "../api/article";
import { defaultThumbnail } from "../utils/thumbnail";
export default {
  name: "Home",
  setup() {
    let pageSize = 10;
    let postArticles = reactive([]);
    let articleCount = ref(0);
    onCurrentPageChanged(1);
    function onCurrentPageChanged(pageNum) {
      getPostArticleList(pageNum, pageSize).then(data => {
        articleCount.value = parseInt(data.total);
        data.rows.forEach(article => {
          article.createTime = article.createTime.split(" ")[0];
          article.thumbnail = article.thumbnail || defaultThumbnail;
        });

        // 重置列表内容
        postArticles.splice(0, postArticles.length, ...data.rows);
      });
    }
    return {
      postArticles,
      articleCount,
      pageSize,
      onCurrentPageChanged
    };
  }
};