import "core-js/modules/es.array.push.js";
import { ref, reactive } from "vue";
import { User, Lock, Postcard, Promotion } from "@element-plus/icons-vue";
import { ElMessage } from "element-plus";
import { register } from "../api/user";
import { md5Encryption } from "../utils/encrypt";
import router from "../router/index";
export default {
  name: "Login",
  components: {
    User,
    Lock,
    Postcard,
    Promotion
  },
  setup() {
    let ruleFormRef = ref();
    let ruleForm = reactive({
      userName: "",
      nickName: "",
      email: "",
      password: "",
      ensurePassword: ""
    });
    let rules = reactive({
      userName: [{
        required: true,
        message: "用户名不能为空",
        trigger: "change"
      }],
      nickName: [{
        required: true,
        message: "昵称不能为空",
        trigger: "change"
      }],
      email: [{
        required: true,
        message: "邮箱地址不能为空",
        trigger: "change"
      }, {
        type: "email",
        message: "请输入正确的邮箱地址",
        trigger: "blur"
      }],
      password: [{
        required: true,
        message: "密码不能为空",
        trigger: "change"
      }]
    });
    function submitForm(form) {
      if (!form) return;
      form.validate(valid => {
        if (!valid) {
          ElMessage.error("请正确填写前辈的信息");
          return;
        }
        if (ruleForm.password !== ruleForm.ensurePassword) {
          ElMessage.error("两次密码不一致");
          return;
        }
        let password = md5Encryption(ruleForm.password);
        register(ruleForm.userName, ruleForm.nickName, ruleForm.email, password).then(data => {
          ElMessage.success("注册用户成功 ✨");
          setTimeout(() => {
            router.push("/login");
          }, 2000);
        });
      });
    }
    return {
      rules,
      ruleForm,
      ruleFormRef,
      submitForm
    };
  }
};