import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "comment-item-avatar"
};
const _hoisted_2 = ["src"];
const _hoisted_3 = {
  class: "comment-item-body"
};
const _hoisted_4 = {
  class: "comment-item-header"
};
const _hoisted_5 = {
  class: "comment-item-info"
};
const _hoisted_6 = {
  class: "comment-item-actions"
};
const _hoisted_7 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item");
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu");
  const _component_el_dropdown = _resolveComponent("el-dropdown");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass($setup.commentClass)
  }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("img", {
    src: $props.comment.avatar,
    onErrorOnce: _cache[0] || (_cache[0] = (...args) => _ctx.fallbackAvatar && _ctx.fallbackAvatar(...args)),
    alt: "用户头像"
  }, null, 40, _hoisted_2)]), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createElementVNode("a", {
    onClick: _cache[1] || (_cache[1] = _withModifiers((...args) => $setup.scrollToAnchor && $setup.scrollToAnchor(...args), ["prevent"]))
  }, "#" + _toDisplayString($props.floorNumber) + "楼 ", 1), _createElementVNode("span", null, _toDisplayString($props.comment.createTime), 1), _createElementVNode("span", null, _toDisplayString($props.comment.userName), 1)]), _createElementVNode("div", _hoisted_6, [_cache[7] || (_cache[7] = _createElementVNode("span", {
    class: "smile-face"
  }, [_createElementVNode("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    height: "16",
    viewBox: "0 0 16 16",
    version: "1.1",
    width: "16"
  }, [_createElementVNode("path", {
    "fill-rule": "evenodd",
    d: "M1.5 8a6.5 6.5 0 1113 0 6.5 6.5 0 01-13 0zM8 0a8 8 0 100 16A8 8 0 008 0zM5 8a1 1 0 100-2 1 1 0 000 2zm7-1a1 1 0 11-2 0 1 1 0 012 0zM5.32 9.636a.75.75 0 011.038.175l.007.009c.103.118.22.222.35.31.264.178.683.37 1.285.37.602 0 1.02-.192 1.285-.371.13-.088.247-.192.35-.31l.007-.008a.75.75 0 111.222.87l-.614-.431c.614.43.614.431.613.431v.001l-.001.002-.002.003-.005.007-.014.019a1.984 1.984 0 01-.184.213c-.16.166-.338.316-.53.445-.63.418-1.37.638-2.127.629-.946 0-1.652-.308-2.126-.63a3.32 3.32 0 01-.715-.657l-.014-.02-.005-.006-.002-.003v-.002h-.001l.613-.432-.614.43a.75.75 0 01.183-1.044h.001z"
  })])], -1)), _createElementVNode("span", {
    class: "more-actions",
    onClick: _cache[2] || (_cache[2] = (...args) => $setup.showMoreActionsMenu && $setup.showMoreActionsMenu(...args))
  }, _cache[3] || (_cache[3] = [_createElementVNode("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    role: "img",
    height: "16",
    width: "16",
    viewBox: "0 0 16 16",
    version: "1.1"
  }, [_createElementVNode("path", {
    d: "M8 9a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM1.5 9a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm13 0a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"
  })], -1)])), _createVNode(_component_el_dropdown, {
    ref: "moreActionsMenu",
    trigger: "contextmenu",
    onCommand: $setup.handleMenuCommand
  }, {
    dropdown: _withCtx(() => [_createVNode(_component_el_dropdown_menu, null, {
      default: _withCtx(() => [_createVNode(_component_el_dropdown_item, {
        command: "reply"
      }, {
        default: _withCtx(() => _cache[4] || (_cache[4] = [_createTextVNode("回复")])),
        _: 1
      }), $setup.canModify ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
        key: 0,
        command: "update"
      }, {
        default: _withCtx(() => _cache[5] || (_cache[5] = [_createTextVNode("修改")])),
        _: 1
      })) : _createCommentVNode("", true), $setup.canModify ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
        key: 1,
        command: "delete"
      }, {
        default: _withCtx(() => _cache[6] || (_cache[6] = [_createTextVNode("删除")])),
        _: 1
      })) : _createCommentVNode("", true)]),
      _: 1
    })]),
    _: 1
  }, 8, ["onCommand"])])]), _createElementVNode("div", {
    class: "comment-item-content",
    innerHTML: $setup.content
  }, null, 8, _hoisted_7)])], 2);
}