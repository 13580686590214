import { computed, reactive, ref } from "vue";
import { getPostArticleList } from "../../api/article";
import { defaultThumbnail } from "../../utils/thumbnail";
import { mapState } from "../../store/map";
export default {
  name: "TagDetails",
  setup(props) {
    let pageSize = 10;
    let postArticles = reactive([]);
    let articleCount = ref(0);
    let {
      tagCounts
    } = mapState("tagAbout");
    let tagName = computed(() => {
      let map = Object.fromEntries(tagCounts.value.map(i => [i.id, i.name]));
      return map[props.id];
    });
    onCurrentPageChanged(1);
    function onCurrentPageChanged(pageNum) {
      getPostArticleList(pageNum, pageSize, null, props.id).then(data => {
        articleCount.value = parseInt(data.total);
        data.rows.forEach(article => {
          article.createTime = article.createTime.split(" ")[0];
          article.thumbnail = article.thumbnail || defaultThumbnail;
        });
        postArticles.splice(0, postArticles.length, ...data.rows);
      });
    }
    window.scrollTo({
      top: 0
    });
    return {
      tagName,
      postArticles,
      articleCount,
      pageSize,
      onCurrentPageChanged
    };
  },
  props: ["id", "name"]
};