import { reactive, ref } from "vue";
import store from "../store";
export default {
  name: "KilaKilaHeader",
  setup() {
    let drawer = ref(false);
    let menuItems = reactive([{
      name: "首页",
      icon: ["fab", "fort-awesome"],
      href: "/"
    }, {
      name: "归档",
      icon: ["fas", "box-archive"],
      href: "/archive"
    }, {
      name: "分类",
      icon: ["fas", "folder"],
      href: "/category"
    }, {
      name: "标签",
      icon: ["fas", "tags"],
      href: "/tag"
    }, {
      name: "个人中心",
      icon: ["fas", "user"],
      href: "/user/settings"
    }]);
    if (store.state.adminAbout.isAdmin) {
      menuItems.splice(4, 0, {
        name: "新随笔",
        icon: ["fas", "pen"],
        href: "/article/add"
      });
    }
    window.addEventListener("resize", function () {
      let scale = window.devicePixelRatio;
      let width = document.documentElement.clientWidth * scale;
      if (width > 900 * scale) {
        drawer.value = false;
      }
    });
    return {
      menuItems,
      drawer
    };
  }
};