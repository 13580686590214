import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  id: "arctile-edit"
};
const _hoisted_2 = {
  class: "edit-card"
};
const _hoisted_3 = {
  class: "inline-form-row"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_kila_kila_header = _resolveComponent("kila-kila-header");
  const _component_kila_kila_wife_cover = _resolveComponent("kila-kila-wife-cover");
  const _component_el_input = _resolveComponent("el-input");
  const _component_el_form_item = _resolveComponent("el-form-item");
  const _component_el_select_v2 = _resolveComponent("el-select-v2");
  const _component_mavon_editor = _resolveComponent("mavon-editor");
  const _component_kila_kila_uploader = _resolveComponent("kila-kila-uploader");
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_form = _resolveComponent("el-form");
  const _component_kila_kila_footer = _resolveComponent("kila-kila-footer");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_kila_kila_header), _createVNode(_component_kila_kila_wife_cover, null, {
    default: _withCtx(() => [_createElementVNode("h1", null, _toDisplayString($setup.title), 1)]),
    _: 1
  }), _createElementVNode("div", _hoisted_2, [_cache[10] || (_cache[10] = _createElementVNode("h1", {
    class: "kila-kila-blog-title"
  }, "HadLuo的个人博客", -1)), _createVNode(_component_el_form, {
    ref: "ruleFormRef",
    model: $setup.ruleForm,
    rules: $setup.rules,
    "label-width": "60px",
    class: "edit-ruleForm"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_form_item, {
      prop: "title",
      label: "标题"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_input, {
        modelValue: $setup.ruleForm.title,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $setup.ruleForm.title = $event),
        class: "w-50 m-3",
        size: "large",
        placeholder: "给博客起一个好标题很重要哦"
      }, null, 8, ["modelValue"])]),
      _: 1
    }), _createVNode(_component_el_form_item, {
      prop: "summary",
      label: "摘要"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_input, {
        modelValue: $setup.ruleForm.summary,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $setup.ruleForm.summary = $event),
        class: "w-50 m-3",
        size: "large",
        type: "textarea",
        placeholder: "简要介绍一下这篇博客吧"
      }, null, 8, ["modelValue"])]),
      _: 1
    }), _createElementVNode("div", _hoisted_3, [_createVNode(_component_el_form_item, {
      label: "分类",
      prop: "category",
      inline: "",
      style: {
        "width": "35%"
      }
    }, {
      default: _withCtx(() => [_createVNode(_component_el_select_v2, {
        modelValue: $setup.ruleForm.category,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => $setup.ruleForm.category = $event),
        options: $setup.categories,
        placeholder: "给博客分个类吧",
        style: {
          "width": "100%",
          "vertical-align": "middle"
        },
        "allow-create": "",
        filterable: "",
        clearable: ""
      }, null, 8, ["modelValue", "options"])]),
      _: 1
    }), _createVNode(_component_el_form_item, {
      label: "标签",
      prop: "tag",
      inline: "",
      style: {
        "width": "60%"
      }
    }, {
      default: _withCtx(() => [_createVNode(_component_el_select_v2, {
        modelValue: $setup.ruleForm.tags,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => $setup.ruleForm.tags = $event),
        options: $setup.tags,
        placeholder: "来贴几个标签吧",
        style: {
          "width": "100%",
          "vertical-align": "middle"
        },
        multiple: "",
        "allow-create": "",
        filterable: "",
        clearable: ""
      }, null, 8, ["modelValue", "options"])]),
      _: 1
    })]), _createVNode(_component_el_form_item, {
      prop: "content",
      label: "内容"
    }, {
      default: _withCtx(() => [_createVNode(_component_mavon_editor, {
        modelValue: $setup.ruleForm.content,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => $setup.ruleForm.content = $event),
        id: "mavon",
        codeStyle: "atom-one-dark",
        autofocus: false,
        boxShadow: false,
        onImgAdd: $setup.onImageAdded,
        ref: "mavonRef"
      }, null, 8, ["modelValue", "onImgAdd"])]),
      _: 1
    }), _createVNode(_component_el_form_item, {
      prop: "thumbnail",
      label: "缩略图"
    }, {
      default: _withCtx(() => [_createVNode(_component_kila_kila_uploader, {
        onUploaded: $setup.handleThumbnailUploaded,
        onAboutToUpload: $setup.handleAboutToUploadThumbnail,
        onRemoved: $setup.handleRemoveThumbnail,
        ref: "uploaderRef"
      }, null, 8, ["onUploaded", "onAboutToUpload", "onRemoved"])]),
      _: 1
    }), _createVNode(_component_el_form_item, null, {
      default: _withCtx(() => [_createVNode(_component_el_button, {
        type: "primary",
        onClick: _cache[5] || (_cache[5] = $event => $setup.submitForm($setup.ruleFormRef, false)),
        color: "#1892ff",
        class: "el-button",
        id: "submit-button"
      }, {
        default: _withCtx(() => _cache[7] || (_cache[7] = [_createTextVNode("立刻发布")])),
        _: 1
      }), _createVNode(_component_el_button, {
        class: "el-button",
        id: "draft-button",
        onClick: _cache[6] || (_cache[6] = $event => $setup.submitForm($setup.ruleFormRef, true))
      }, {
        default: _withCtx(() => _cache[8] || (_cache[8] = [_createTextVNode("存为草稿")])),
        _: 1
      }), _createVNode(_component_el_button, {
        onClick: $setup.cancelSubmit
      }, {
        default: _withCtx(() => _cache[9] || (_cache[9] = [_createTextVNode("取消")])),
        _: 1
      }, 8, ["onClick"])]),
      _: 1
    })]),
    _: 1
  }, 8, ["model", "rules"])]), _createVNode(_component_kila_kila_footer)]);
}