import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "cover-word-container"
};
const _hoisted_2 = {
  class: "cover-title particletext bubbles"
};
const _hoisted_3 = {
  class: "cover-content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_arrow_down = _resolveComponent("arrow-down");
  const _component_el_icon = _resolveComponent("el-icon");
  return _openBlock(), _createElementBlock("div", {
    style: _normalizeStyle($setup.coverStyle),
    class: "cover"
  }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("h1", _hoisted_2, _toDisplayString($props.title), 1), _createElementVNode("p", _hoisted_3, _toDisplayString($props.content), 1), _createVNode(_component_el_icon, {
    size: "50px",
    class: "arrow-down",
    onClick: $setup.scrollToContent
  }, {
    default: _withCtx(() => [_createVNode(_component_arrow_down)]),
    _: 1
  }, 8, ["onClick"])])], 4);
}