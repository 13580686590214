import { reactive } from "vue";
import { ArrowDown } from "@element-plus/icons-vue";
import createParticles from "../utils/text-effect";
export default {
  name: "KilaKilaCover",
  components: {
    ArrowDown
  },
  setup(props) {
    let coverStyle = reactive({
      background: `url(${require("@/" + props.imgUrl)})`,
      backgroundSize: "cover"
    });
    function scrollToContent() {
      let h = document.getElementsByClassName("cover")[0].scrollHeight;
      window.scrollTo({
        top: h,
        behavior: "smooth"
      });
    }
    return {
      coverStyle,
      scrollToContent
    };
  },
  mounted() {
    createParticles();
  },
  props: {
    imgUrl: {
      type: String,
      require: true
    },
    title: {
      type: String,
      require: true
    },
    content: {
      type: String,
      require: true
    }
  }
};